import { Launch } from '@lightningjs/sdk';
import { App } from './mitu_app';
function isInspectorWindow(window) {
  return window.attachInspector != null && window.lng != null;
}
export default function LaunchApp(appSettings, platformSettings) {
  var _platformSettings$ins;
  var appData = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  console.info('Launching with settings', JSON.stringify({
    appSettings: appSettings,
    platformSettings: platformSettings,
    appData: appData
  }, null, 2));
  if (((_platformSettings$ins = platformSettings.inspector) === null || _platformSettings$ins === void 0 ? void 0 : _platformSettings$ins.toString()) === 'true' && isInspectorWindow(window)) {
    window.attachInspector(window.lng);
  }
  return Launch(App, appSettings, platformSettings, appData);
}