var _excluded = ["width", "height"],
  _excluded2 = ["w", "h"];
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
/* eslint-disable @typescript-eslint/no-explicit-any */
import { isMediaDetailsExtra } from '@adiffengine/engine-types';
import { Router } from '@lightningjs/sdk';
import defer from 'lodash-es/defer';
import isFinite from 'lodash-es/isFinite';
import isFunction from 'lodash-es/isFunction';
import isNumber from 'lodash-es/isNumber';
import isString from 'lodash-es/isString';
import { Debugger } from './debugger';
var debug = new Debugger('lightning-tools');
export var cp = function cp(x) {
  return x;
};
export var copyParent = {
  x: cp,
  h: cp,
  w: cp,
  y: cp
};
export function passSignal(s) {
  var fallback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return s === true || s === false ? s : fallback;
}
export function firstSeason(seasons) {
  if (seasons == null || seasons.length === 0) return null;else if (seasons.length === 1) return seasons[0];
  var hasNumbers = seasons.reduce(function (acc, s) {
    return acc === true || isNumber(s.number);
  }, false);
  if (hasNumbers) {
    var first = seasons.sort(function (a, b) {
      var _a$number, _b$number;
      return ((_a$number = a.number) !== null && _a$number !== void 0 ? _a$number : Infinity) - ((_b$number = b.number) !== null && _b$number !== void 0 ? _b$number : Infinity);
    })[0];
    return first;
  }
  return seasons[0];
}
export function firstEpisode(episodes) {
  if (episodes == null || episodes.length === 0) return null;else if (episodes.length === 1) return episodes[0];
  var hasNumbers = episodes.reduce(function (acc, s) {
    return !!(acc || isNumber(s.episodeNumber));
  }, false);
  if (hasNumbers) {
    var first = episodes.sort(function (a, b) {
      var _a$episodeNumber, _b$episodeNumber;
      return ((_a$episodeNumber = a.episodeNumber) !== null && _a$episodeNumber !== void 0 ? _a$episodeNumber : Infinity) - ((_b$episodeNumber = b.episodeNumber) !== null && _b$episodeNumber !== void 0 ? _b$episodeNumber : Infinity);
    })[0];
    return first;
  }
  return episodes[0];
}
export function parseMedia(media) {
  if (!media) return null;
  var out = media.reduce(function (acc, item) {
    switch (item.type) {
      case 'movie':
      case 'episode':
        acc.content = item;
        break;
      case 'extra':
        acc.extras = Array.isArray(acc.extras) && isMediaDetailsExtra(item) ? [].concat(_toConsumableArray(acc.extras), [item]) : [item];
    }
    return acc;
  }, {});
  return out;
}
export function isID(x) {
  return x && isNumber(x) || isString(x);
}
export function defaultBackHandler() {
  var path = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'home';
  var history = Router.getHistory();
  if (history.length === 0) {
    defer(function () {
      Router.navigate(path.replace(/^\//, ''), false);
    });
    return true;
  }
  return false;
}
export function hasFinals(x) {
  return x && isNumber(x.finalH) && isNumber(x.finalW) && isNumber(x.finalX) && isNumber(x.finalY);
}
export function hasCoords(item) {
  return item && isNumber(item.finalX) && isNumber(item.finalY);
}
export function getCenterCoordinates(item) {
  var useFinals = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  if (item && hasFinals(item) && hasCoords(item)) {
    var _coords = getCoords(item, {
      x: 0,
      y: 0
    }, useFinals);
    return _objectSpread(_objectSpread({}, _coords), {}, {
      width: item.finalW,
      height: item.finalH
    });
  }
  return null;
}
export function finalsToCoordinateDimensions(x) {
  return {
    x: x.finalX,
    y: x.finalY,
    height: x.finalH,
    width: x.finalW
  };
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export var isCoordinateList = function isCoordinateList(x) {
  return x && isFunction(x.setIndex) && Array.isArray(x.items);
};
export function trackerId(string) {
  var out = string.replace(/#[0-9]*/g, '');
  out = out.replace(':', '');
  return out;
}
export var centerX = function centerX(dims) {
  return dims.x + dims.width / 2;
};
export var centerY = function centerY(dims) {
  return dims.y + dims.height / 2;
};
export function getClosestIndexByDimension(dimension, coords, list, useFinals) {
  if (useFinals === undefined) {
    useFinals = list.slice(0, 3).reduce(function (acc, item) {
      return acc || item.x !== item.finalX;
    }, false);
  }
  var coordinator = dimension === 'x' ? centerX : centerY;
  var x = coordinator(coords);
  var closest = list.reduce(function (acc, c, index) {
    if (validateCoordinated(c)) {
      var testCoords = getCoordinateDimensions(c, useFinals);
      var testX = coordinator(testCoords);
      var _distance = Math.abs(x - testX);
      if (_distance < acc.distance || acc.current === -1) {
        acc.distance = _distance;
        acc.current = index;
      }
    }
    return acc;
  }, {
    current: -1,
    distance: Infinity
  });
  return closest.current >= 0 ? closest.current : null;
}
export function getClosestIndexByX(coords, list, useFinals) {
  if (Array.isArray(list) && list.length) {
    var closest = getClosestIndexByDimension('x', coords, list, useFinals);
    return closest;
  }
  return null;
}
export function getClosestIndexByY(coords, list) {
  var useFinals = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  if (Array.isArray(list) && list.length) {
    return getClosestIndexByDimension('y', coords, list, useFinals);
  }
  return null;
}
export function setClosestInList(coords, list) {
  var useFinals = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  if (isCoordinateList(list)) {
    var currentDistance = Infinity;
    var currentIndex = -1;
    if (isGoodNumber(list._lastIndex, true)) {
      currentIndex = list._lastIndex;
    } else {
      var currentCoords = getCenterCoordinates(coords, useFinals);
      if (currentCoords) {
        list.items.forEach(function (item, idx) {
          var itemCoords = getCenterCoordinates(item, useFinals);
          if (itemCoords) {
            var _distance2 = distanceBetween(currentCoords, itemCoords);
            if (_distance2 < currentDistance) {
              currentDistance = _distance2;
              currentIndex = idx;
            }
          }
        });
      }
      if (currentIndex >= 0 && currentIndex < list.items.length - 1 && list.index !== currentIndex) list.setIndex(currentIndex);
    }
  } else {
    console.warn('Not a coordinate list');
  }
}
export function removeNullable(item) {
  return Object.entries(item).reduce(function (acc, _ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      key = _ref2[0],
      value = _ref2[1];
    if (item[key] != null) acc[key] = value;
    return acc;
  }, {});
}
export function prettyTime(value) {
  if (isGoodNumber(value)) {
    if (value > 432000) value = value / 1000;
    var hours = Math.floor(value / 60 / 60),
      minutes = Math.floor((value - hours * 60 * 60) / 60),
      seconds = Math.round(value - hours * 60 * 60 - minutes * 60);
    return hours + ':' + (minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds);
  } else {
    console.warn('Got a bad value for pretty time', value);
    return "00:00:00";
  }
}
export function isSavedPosition(value) {
  return value && isGoodNumber(value.position) && isGoodNumber(value.index, true);
}
export function isGoodNumber(value) {
  var positive = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return isNumber(value) && isFinite(value) && (!positive || value >= 0);
}
export function isGoodString(value) {
  return isString(value) && value.trim().length > 0;
}
export function makeId(ci) {
  return "".concat(ci.id, ":").concat(ci.type);
}
export function distanceBetween(a, b) {
  return distance(itemCenter(a), itemCenter(b));
}
export function distance(a, b) {
  return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
}
export function itemCenter(a) {
  return {
    x: (a.x + a.width) / 2,
    y: (a.y + a.height) / 2
  };
}
function validateCoordinated(x) {
  var shout = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  if (!x || !isNumber(x.x) || !isNumber(x.y)) {
    if (shout) console.warn('Not Coordinated', x);
    return false;
  }
  return true;
}
function validateFinalCoordinated(x) {
  var shout = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  if (!isNumber(x.finalX) || !isNumber(x.finalY)) {
    if (shout) console.warn('Not Coordinated', x);
    return false;
  }
  return true;
}
export function getCoords(coordinated) {
  var current = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    x: 0,
    y: 0
  };
  var useFinals = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  if (useFinals && validateFinalCoordinated(coordinated)) {
    current.x += coordinated.finalX;
    current.y += coordinated.finalY;
  } else if (validateCoordinated(coordinated)) {
    current.x += coordinated.x;
    current.y += coordinated.y;
  }
  if (coordinated.isRoot === false && coordinated.parent) return getCoords(coordinated.parent, current, useFinals);else return current;
}
export function getCoordinateDimensions(coordinated) {
  var useFinals = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var coords = getCoords(coordinated, {
    x: 0,
    y: 0
  }, useFinals);
  var out = _objectSpread(_objectSpread({}, coords), {}, {
    width: isGoodNumber(coordinated.finalW, true) ? coordinated.finalW : 0,
    height: isGoodNumber(coordinated.finalH, true) ? coordinated.finalH : 0
  });
  return out;
}
export function getPatchFromCoordinates(coords) {
  var width = coords.width,
    height = coords.height,
    axis = _objectWithoutProperties(coords, _excluded);
  return _objectSpread({
    w: width,
    h: height
  }, axis);
}
export function getCoordindatesFromPatch(coords) {
  var w = coords.w,
    h = coords.h,
    axis = _objectWithoutProperties(coords, _excluded2);
  return _objectSpread({
    width: w,
    height: h
  }, axis);
}
export function isComponentDimensions(x) {
  return x.w !== undefined;
}
export function normalizeDimensions(x) {
  return isComponentDimensions(x) ? x : getPatchFromCoordinates(x);
}
export function expandHorizontal(coords, amount) {
  coords = normalizeDimensions(coords);
  return _objectSpread(_objectSpread({}, coords), {}, {
    x: coords.x - amount,
    w: coords.w + amount * 2
  });
}
export function expandVertical(coords, amount) {
  coords = normalizeDimensions(coords);
  return _objectSpread(_objectSpread({}, coords), {}, {
    y: coords.y - amount,
    h: coords.h + amount * 2
  });
}
export function expandDimensions(coords, amount, returnCoords) {
  var returnCoordinates = returnCoords === true;
  var out = _objectSpread(_objectSpread({}, expandVertical(coords, amount)), expandHorizontal(coords, amount));
  return returnCoordinates ? getCoordindatesFromPatch(out) : out;
}
export function withinViewport(coordinated) {
  var precision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0.8;
  var safeArea = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var safety = safeArea === true ? 80 : 0;
  var coordinates = getCoordinateDimensions(coordinated);
  var horizontalOffset = (coordinates.width - coordinates.width * precision) / 2;
  var verticalOffset = (coordinates.height - coordinates.height * precision) / 2;
  var startH = 0 + safety;
  var startW = 0 + safety;
  var finalH = coordinated.stage.h - safety;
  var finalW = coordinated.stage.w - safety;
  var originalWithinY = coordinates.y > 0 && coordinates.y + coordinates.height < finalH;
  var originalWithinX = coordinates.y > 0 && coordinates.x + coordinates.width < finalW;
  var box = {
    verticalOffset: verticalOffset,
    horizontalOffset: horizontalOffset,
    coordinates: coordinates,
    stage: {
      height: coordinated.stage.h,
      width: coordinated.stage.w,
      finalH: finalH,
      finalW: finalW,
      startH: startH,
      startW: startW
    },
    original: {
      withinY: originalWithinY,
      withinX: originalWithinX,
      within: originalWithinX && originalWithinY,
      top: coordinates.y,
      bottom: coordinates.y + coordinates.height,
      left: coordinates.x,
      right: coordinates.x + coordinates.width
    },
    precise: {
      withinY: false,
      withinX: false,
      within: false,
      top: Math.round(coordinates.y + verticalOffset),
      bottom: Math.round(coordinates.y + coordinates.height - verticalOffset),
      left: Math.round(coordinates.x + horizontalOffset),
      right: Math.round(coordinates.x + horizontalOffset)
    }
  };
  box.precise.withinY = box.precise.top > 0 && box.precise.bottom < finalH;
  box.precise.withinX = box.precise.left > 0 && box.precise.right < finalW;
  box.precise.within = box.precise.withinX && box.precise.withinY;
  return box;
}
export function climbForCoordinates(coordinated) {
  var coords = getCoordinateDimensions(coordinated);
  if (coords.width === 0 && coords.height === 0 && coordinated.parent) {
    return climbForCoordinates(coordinated.parent);
  } else if (coords.width === 0 && coords.height === 0) {
    return null;
  } else {
    return coords;
  }
}
export function isArray(x, hasLength) {
  return Array.isArray(x) && (!hasLength || hasLength && x.length > 0);
}
export function isGridPosition(x) {
  return x && isGoodNumber(x.mainIndex, true) && isGoodNumber(x.crossIndex, true) && isGoodNumber(x.lines, true) && isGoodNumber(x.dataLength, true);
}
export function getBestDimensionValue(element, dim) {
  var dimension = dim.toLowerCase();
  var baseDim = element[dimension];
  baseDim = isGoodNumber(baseDim) ? baseDim : 0;
  var finalDim = element["final".concat(dimension.toUpperCase())];
  finalDim = isGoodNumber(finalDim) ? finalDim : 0;
  return finalDim > baseDim ? finalDim : baseDim;
}
export function getBestDimensionValues(element) {
  return {
    h: getBestDimensionValue(element, 'h'),
    w: getBestDimensionValue(element, 'w')
  };
}
function hasCollisionSet(e) {
  return e && (e.collision === 2 || e.collision === true);
}
export function confirmCollisionsAreSet(element) {
  if (!element) return;
  if (element.parent && !hasCollisionSet(element.parent)) {
    ;
    element.parent.collision = 2;
  }
  if (element.parent) {
    confirmCollisionsAreSet(element.parent);
  }
}
export function isHandleHoverComponent(x) {
  return x && x._handleHover;
}
export function isHandleUnHoverComponent(x) {
  return x && isFunction(x._handleUnhover);
}
export function registerHoverable(name, widget) {
  var oldHoverHandler = isHandleHoverComponent(widget) ? widget._handleHover.bind(widget) : null;
  widget.__hoverName = "".concat(name, "::").concat(widget.id);
  widget._handleHover = function (target) {
    if (isFireableComponent(this) && this.collision === true) {
      this.fireAncestors('$hovered', widget);
    }
    if (oldHoverHandler !== null) oldHoverHandler(target);
  }.bind(widget);
}
export function isHoverNamedComponent(c) {
  return isString(c.__hoverName);
}
function isFireableComponent(element) {
  return isFunction(element.fireAncestors);
}
export function getHoverablePath(component) {
  var parts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  if (isHoverNamedComponent(component)) parts.unshift(component.__hoverName);
  if (component.parent) {
    return getHoverablePath(component.parent, parts);
  } else {
    return parts.join('/');
  }
}
export function climbDimensions(e) {
  var deb = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new Debugger('climber');
  if (e) {
    var _e$ref;
    var _coords2 = getCoordinateDimensions(e);
    deb.info('Coords of %s', (_e$ref = e.ref) !== null && _e$ref !== void 0 ? _e$ref : e.id, JSON.stringify(_coords2, null, 2), e);
    if (e.parent) climbDimensions(e.parent, deb);
  }
}
export function testCollision(cursorX, cursorY, cx, cw, cy, ch) {
  return cursorX >= cx && cursorX <= cx + cw && cursorY >= cy && cursorY <= cy + ch;
}